import request from "@/common/request"

//获取枚举值
export const enumList = (enumName, moduleName) => {
    return request("get", "/system/enums/list", {
        enumName: enumName,
        moduleName: moduleName
    });
}

//获取指定code的字典集合
export const dictList = (code) => {
    return request("get", "/system/dict/listByCode", {
        code: code
    });
}

//获取指定地址的list数据集合
export const urlList = (url) => {
    return request("get", url);
}

//获取文件存储url前缀
export const prefixFileUrl = () => {
    return request("get", "/system/oss/prefixUrl");
}

//获取文件存储url前缀
export const viewTableList = (tbName) => {
    return request("get", "/system/viewtable/list", { tbName: tbName });
}
